import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const TextIframeEmbed = ({ slice }) => {
  return (
    <section className="CardsIcons" id={slice.primary.anchor_id || ''}>
      <div className="Slices_Container Container max-width-966">
        <div className="Cards__text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
        </div>
        {slice.items.map((item, index) => {
          return (
            <div key={index}>
              {item.html_embed.text && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.html_embed.text,
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyTextIframeEmbed on PrismicUpdatedPageDataBodyTextIframeEmbed {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      html_embed {
        richText
        text
      }
    }
  }
`
