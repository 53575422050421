import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink, PrismicText } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const CtaQuote = ({ slice }) => {
  const hasImage = isFilled.image(slice.primary.image) === true

  return (
    <section
      className={`CtaQuote ${hasImage ? 'Cta' : ''}`}
      id={slice.primary.anchor_id || ''}
    >
      <div className="Slices_Container Container no-background">
        <div className={`CtaQuote_Container ${hasImage ? 'blue' : ''}`}>
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.keyText(slice.primary.quote) && (
            <p className="quote">{slice.primary.quote}</p>
          )}
          {isFilled.keyText(slice.primary.author) && (
            <p className="author">{slice.primary.author}</p>
          )}
          {isFilled.image(slice.primary.image) && (
            <div className="image_container">
              <GatsbyImage
                image={slice.primary.image.gatsbyImageData}
                alt={slice.primary.image.alt || ''}
                className="rounded_image"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyCtaQuote on PrismicUpdatedPageDataBodyCtaQuote {
    id
    primary {
      anchor_id
      header {
        richText
      }
      quote
      author
      image {
        gatsbyImageData
        url
        alt
      }
    }
  }
`
