import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const StatsTitle = ({ slice }) => {
  return (
    <section
      className="Stats_Title Container Slices_Container"
      id={slice.primary.anchor_id || ''}
    >
      <div className="header_and_text">
        {isFilled.richText(slice.primary.header.richText) && (
          <PrismicRichText field={slice.primary.header.richText} />
        )}
        {isFilled.richText(slice.primary.text.richText) && (
          <PrismicRichText field={slice.primary.text.richText} />
        )}
        <div className="stats-grid">
          {slice.items.map((stat) => (
            <div key={stat.minor_stat} className="blue_container">
              <div className="major-stat blue">{stat.major_stat}</div>
              <div className="minor-stat ">{stat.minor_stat}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyStatsTitle on PrismicUpdatedPageDataBodyStatsTitle {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      major_stat
      minor_stat
    }
  }
`
