import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'
import { MdArrowForwardIos } from 'react-icons/md'

export const CardsIcons = ({ slice }) => {
  const isThreeColumns = slice.primary.number_of_columns === true
  const blueBackground = slice.primary.blue_background === true
  return (
    <section
      className={`CardsIcons ${blueBackground ? 'blue-background' : ''}`}
      id={slice.primary.anchor_id || ''}
    >
      <div className="Slices_Container Container">
        <div className="Cards__text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
        </div>
        <div
          className={
            isThreeColumns ? 'CardsIcons_Container_3' : 'CardsIcons_Container_2'
          }
        >
          {slice.items.map((item, index) => (
            <div key={index} className={isThreeColumns ? 'card-3' : 'card'}>
              <div>
                {isFilled.image(item.icon) && (
                  <GatsbyImage
                    image={item.icon.gatsbyImageData}
                    alt={item.icon.alt || ''}
                  />
                )}
              </div>
              <div>
                {isFilled.richText(item.header.richText) && (
                  <span id="card-header">
                    <PrismicRichText field={item.header.richText} />
                  </span>
                )}
                {isFilled.richText(item.text.richText) && (
                  <PrismicRichText field={item.text.richText} />
                )}
              </div>
              {isFilled.link(item.button_link) &&
                isFilled.keyText(item.button_label) && (
                  <PrismicLink
                    href={item.button_link.url}
                    className="primary-btn align-center"
                  >
                    {item.button_label}
                    <MdArrowForwardIos className="icon-margin" />
                  </PrismicLink>
                )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyCardsIcons on PrismicUpdatedPageDataBodyCardsIcons {
    id
    primary {
      anchor_id
      number_of_columns
      blue_background
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      icon {
        gatsbyImageData
        url
        alt
      }
      header {
        richText
      }
      text {
        richText
      }
      button_link {
        url
      }
      button_label
    }
  }
`
